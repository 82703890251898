import React from 'react';
import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';

const StyledPostContainer = styled.main`
  max-width: 1000px;
`;
const StyledPostHeader = styled.header`
  margin-bottom: 50px;
  .tag {
    margin-right: 10px;
  }
`;
const StyledPostContent = styled.div`

h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
    padding: 0 5rem; 
    @media (max-width: 768px) {
      padding: 0 1rem; 
    }
  }
    h2 {
    font-size: clamp(24px,5vw,28px);
    }
    ul, ol{
    padding: 0 8rem; 
    line-height: 1.5;
    @media (max-width: 768px) {
    padding: 0 2rem;
}
    li {
    padding-bottom: 16px;
    }
    }
  blockquote {
   margin: 0 4rem;
   border-width: 3px; 
   @media (max-width: 768px) {
      margin: 0 1rem; 
    }
   p {
    padding: 0 1rem; 
   }
  }
  p {
    margin: 1em 0 2em;
    line-height: 1.5;
    padding: 0 5rem; 

    @media (max-width: 768px) {
      padding: 0 1rem; 
    }
    // color: var(--header);

    .gatsby-resp-image-wrapper {
      position: relative;
      margin: 4em -5em!important;
      border-radius: 12px;
        overflow: hidden;

      @media (max-width: 768px) {
        margin: 4em -1em!important; 
      }

      &  img {
    
         ${({ theme }) => theme.mixins.boxShadow};
      }
      

      
    }
  }

  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }

  code {
    background-color: var(--line);
    color: var(--lightest-slate);
    border-radius: var(--border-radius);
    font-size: var(--fz-sm);
    padding: 0.2em 0.4em;
  }

  pre code {
    background-color: transparent;
    padding: 0;
  }
`;

const PostTemplate = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { title, date, tags } = frontmatter;

  return (
    <Layout location={location}>
      <Helmet title={title} />

      <StyledPostContainer>
        <span className="breadcrumb">
          <span className="arrow">&larr;</span>
          <Link to="/">Home</Link>
        </span>

        <StyledPostHeader>
          <h1 className="medium-heading">{title}</h1>
        </StyledPostHeader>

        <StyledPostContent dangerouslySetInnerHTML={{ __html: html }} />
      </StyledPostContainer>
    </Layout>
  );
};

export default PostTemplate;

PostTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        date
        slug
        tags
      }
    }
  }
`;
