import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { Icon } from '@components/icons';

const StyledProjectsGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};

  a {
    position: relative;
    z-index: 1;
  }
`;

const StyledProject = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-colour);
  padding: 28px;
  overflow: hidden;
  background-color: var(--background-opaque); 
  margin-bottom: 36px;

  @media (max-width: 800px) {
     flex-direction: column-reverse!important;
 
     .project-image{
         margin-bottom: 24px;}
  .project-content, .project-image{
    width: 100%!important;
    }
  }


${({ theme }) => theme.mixins.boxShadow};

  @media (max-width: 768px) {
    ${({ theme }) => theme.mixins.boxShadow};
  }


  &:nth-of-type(odd)  {
  flex-direction: row-reverse;
    .project-content {
          padding: 0 0 0 28px;


      @media (max-width: 800px) {
        padding: 0;
      }
    }
    .project-tech-list {
      justify-content: flex-start;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }
    }
    .project-links {
      justify-content: flex-end;
      margin-left: 0;
      margin-right: -10px;

      @media (max-width: 768px) {
        justify-content: flex-start;
        margin-left: -10px;
        margin-right: 0;
      }
    }
  }

  .project-content {
    width: 55%;
    // position: relative;
    padding: 0 28px 0 0;
    z-index: 20;
    border-radius: var(--border-radius);
  }

  .project-overline {
    margin: 10px 0;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
  }

  .project-title {
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 28px);
    a{
    position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 24;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    @media (min-width: 768px) {
      margin: 0 0 20px;
    }

  }

  .project-description {
    position: relative;
    z-index: 20;

    p{
      font-size: var(--fz-lg);
    }
    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }
  }

  .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0 10px 10px 0;
      color: var(--header);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      white-space: nowrap;
      border-radius: 12px;
      background: #fff1;
      padding: 2px 6px;
    }

    @media (max-width: 768px) {
      margin: 10px 0;
    }
  }

  .project-links {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    color: var(--lightest-slate);

    a {
      ${({ theme }) => theme.mixins.flexCenter};
      padding: 10px;

      &.external {
        svg {
          width: 22px;
          height: 22px;
          margin-top: -4px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .project-image {
    width: 45%;
    position: relative;
    display: block;
    z-index: 1;
    a {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;
      vertical-align: middle;
      position: relative;

      &:hover {
        &:before,
        .img {
          background: transparent;
          filter: none;
        }
      }

    }

  }
`;

const Featured = () => {
  const data = useStaticQuery(graphql`
    query {
      featured: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/featured/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              cover {
                childImageSharp {
                  fluid(maxWidth: 700, traceSVG: { color: "#64ffda" }) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              tech
              github
              external
            }
            html
          }
        }
      }
    }
  `);

  const featuredProjects = data.featured.edges.filter(({ node }) => node);

  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  return (
    <section id="projects">
      <h2 className="numbered-heading" ref={revealTitle}>
        Some things I’ve worked on <span className="line"></span>
      </h2>

      <StyledProjectsGrid>
        {featuredProjects &&
          featuredProjects.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { external, title, tech, cover } = frontmatter;

            return (
              <StyledProject key={i} ref={el => (revealProjects.current[i] = el)}>
                <div className="project-content">
                  <div>
                    {/* <p className="project-overline">Featured Project</p> */}

                    <h3 className="project-title">
                      <a href={external}>{title}</a>
                    </h3>

                    <div
                      className="project-description"
                      dangerouslySetInnerHTML={{ __html: html }}
                    />

                    {/* {tech.length && (
                      <ul className="project-tech-list">
                        {tech.map((tech, i) => (
                          <li key={i}>{tech}</li>
                        ))}
                      </ul>
                    )} */}
                  </div>
                </div>

                <div className="project-image">
                  <a href={external}>
                    <Img fluid={cover.childImageSharp.fluid} alt={title} className="img" />
                  </a>
                </div>
              </StyledProject>
            );
          })}
      </StyledProjectsGrid>
    </section>
  );
};

export default Featured;
