import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { email } from '@config';
import { navDelay, loaderDelay } from '@utils';
import Inner from '../../images/circle_inner.png'
import Outer from '../../images/circle_outer.png'

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;
  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h3 {
    margin: 0 0  10px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h1, h2 {
    margin: 0;
    font-size: clamp(30px, 12vw, 68px);
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 0.9;
  }
  h2 {
    font-size: clamp(24px, 8vw, 44px);
  }

  p {
    max-width: 500px;
    font-size: 18px;
    color: #eee;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
  .m-intro{
    position: absolute;
    width: 70%;
    height: 100vh;
    top: 0;
    right: 0;
    filter: hue-rotate(205deg);
    z-index: -1;
    @media (max-width: 768px) {
      display: none;
    }

    &:before, &:after{
      display:block;
      content:" ";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position:absolute;
      transform-origin:center;
      background-position:center;
      background-repeat:no-repeat;
      z-index:50;
    }
    
    &:before{
      background-image:url(${Inner});
      background-size:contain;
      animation: big-spin 16s linear infinite;
    }
    
    &:after{
      background-image:url(${Outer});
      background-size:contain;
      animation: big-spin 16s linear infinite;
  
    }
  }
  

  @keyframes big-spin {
    0% {
       transform: rotate(0deg);
    }
    100% {
       transform: rotate(360deg);
    }
   }

  .line {
      position: relative;
      width: 80px;
      height: 10px;
      display: block;
      margin: 20px 0;
      transform: translate(-25%) scale(0.5);

      &:before, &:after{
        content:'';
        position: absolute;
        height: 10px;
        width: 200%;
      }
      &:before {
        background: linear-gradient(45deg, transparent, transparent 49%, var(--line) 49%, transparent 51%);
      }
      &:after {
          background: linear-gradient(-45deg, transparent, transparent 49%, var(--line) 49%, transparent 51%);
      }
      &:before, &:after{
        background-size: 20px 20px;
      }
    }

    //  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    // background-size: 400% 400%;
    // animation: gradient 15s ease infinite;

    // @keyframes gradient {
    // 0% {
    //     background-position: 0% 50%;
    // }
    // 50% {
    //     background-position: 100% 50%;
    // }
    // 100% {
    //     background-position: 0% 50%;
    // }
    // }
.bg-bubbles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 1;
  
  li{
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: #fff1;
    bottom: -160px;
    
    -webkit-animation: square 25s infinite;
    animation:         square 25s infinite;
    
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    
    &:nth-child(1){
      left: 10%;
    }
    
    &:nth-child(2){
      left: 20%;
      
      width: 80px;
      height: 80px;
      
      animation-delay: 2s;
      animation-duration: 17s;
    }
    
    &:nth-child(3){
      left: 25%;
      animation-delay: 4s;
    }
    
    &:nth-child(4){
      left: 40%;
      width: 60px;
      height: 60px;
      
      animation-duration: 22s;
      
      background-color: #fff2;
    }
    
    &:nth-child(5){
      left: 70%;
    }
    
    &:nth-child(6){
      left: 80%;
      width: 120px;
      height: 120px;
      
      animation-delay: 3s;
      background-color: #fff3;
    }
    
    &:nth-child(7){
      left: 32%;
      width: 160px;
      height: 160px;
      
      animation-delay: 7s;
    }
    
    &:nth-child(8){
      left: 55%;
      width: 20px;
      height: 20px;
      
      animation-delay: 15s;
      animation-duration: 40s;
    }
    
    &:nth-child(9){
      left: 25%;
      width: 10px;
      height: 10px;
      
      animation-delay: 2s;
      animation-duration: 40s;
      background-color: #fff4;
    }
    
    &:nth-child(10){
      left: 90%;
      width: 160px;
      height: 160px;
      
      animation-delay: 11s;
    }
  }
}

@-webkit-keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-1500px) rotate(600deg); }
}
@keyframes square {
  0%   { transform: translateY(0); }
  100% { transform: translateY(-1500px) rotate(600deg); }
}

`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <span className="line"></span>;
  const two = <h1>Luke Balson</h1>;
  const three = <h2>Product Designer</h2>;
  const six = <div className='m-intro'></div>;
  const four = (
    <p>
      Solving problems with thoughtful, user-centered design solutions that bridge creativity and functionality </p>
    );
    const five = (
      <a href={`mailto:${email}`} className="email-link">
        Get In Touch
      </a>
    );

  const items = [one, two, three, four, one];

  return (
    <StyledHeroSection>
      <div className='m-intro'></div>
      {/* <ul className="bg-bubbles"><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul> */}
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
              <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledHeroSection>
  );
};

export default Hero;
