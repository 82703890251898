import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig, email } from '@config';
import sr from '@utils/sr';
import { Social } from '@components';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }
    ul{
    display: flex;
    flex-direction: column;
    }

  .line {
    position: relative;
    width: 200px;
    height: 10px;
    display: inline-block;
    margin: 20px auto;
    transform: translate(-25%) scale(0.5);

    &:before, &:after{
      content:'';
      position: absolute;
      height: 10px;
      width: 100%;
    }
    &:before {
      background: linear-gradient(45deg, transparent, transparent 49%, var(--line) 49%, transparent 51%);
    }
    &:after {
        background: linear-gradient(-45deg, transparent, transparent 49%, var(--line) 49%, transparent 51%);
    }
    &:before, &:after{
      background-size: 20px 20px;
    }
  }

  .title {
    font-size: clamp(20px, 5vw, 30px);
    margin-bottom: 24px;
  }

`;

const Contact = () => {
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <span className="line"></span>

      <h6 className="title">Design is a collaborative journey and I'm always eager for the next challenge.</h6>
      <p>Still scrolling? Let's connect!</p>
      <Social />
    </StyledContactSection>
  );
};

export default Contact;
